<template>
  <div id="container" class="survey h-screen mx-auto max-w-screen-sm flex flex-col flex-nowrap justify-between pt-10 pb-24">
    <div
      id="questionNo"
      class="h-8 text-4xl px-4 flex flex-col justify-center items-center"
    >{{ currentQuestionIndex + 1 }}</div>
    <div
      id="question"
      class="h-20 text-3xl leading-none px-4 flex flex-col justify-center items-center"
    >{{ currentQuestion.question }}</div>

    <ol id="answer" class="text-xl px-4 space-y-2">
      <li
        class="h-16 p-2 text-xl leading-none flex flex-col justify-center items-center"
        v-for="answer in currentQuestion.answers"
        v-bind:key="answer"
        @click="selectAndProceed(answer)"
      >{{ answer.text }}</li>
    </ol>

    <apexchart id="chart" type="radar" :options="chartOptions" :series="surveyResultChart"></apexchart>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#container {
  color: #333;
}

#widthLimiting {
  max-width: 640px;
  height: 100%;
}
#question {
  /* height: 5em; */
  white-space: pre-wrap;
}

#answer li {
  border: 1px solid;
  border-radius: 0.5rem;
  cursor: pointer;
  white-space: pre-wrap;
}

#chart {
  max-width: 640px;
}
</style>

<script>
import VueApexCharts from 'vue3-apexcharts'
import _ from 'lodash'

export default {
  name: 'Survey',
  components: { apexchart: VueApexCharts },
  methods: {
    prev() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--
      }
      console.warn('Not Implemented')
    },
    selectAndProceed(answer) {
      console.log(answer)
      if (this.done === false) {
        this.answerString += answer.result
        if (this.currentQuestionIndex < this.questions.length - 1) {
          this.currentQuestionIndex++
        } else {
          this.done = true
          console.log('done')
        }
      }
    }
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex]
    },
    surveyResult() {
      const axis = 'ESTJINFP'.split('')
      const countsByAxis = axis.map(v => _.countBy(this.answerString)[v] ?? 0)
      console.log(countsByAxis)
      return countsByAxis
    },
    surveyResultChart() {
      return [{ name: 'series-1', data: this.surveyResult }]
    },
    surveyResultMBTI() {
      if (this.done) {
        const t0 = 'ESTJ'
        const t1 = 'INFP'
        const d0 = this.surveyResult.slice(0, 4)
        const d1 = this.surveyResult.slice(4, 8)

        let result = ''
        for (var i = 0; i < 4; i++) {
          result += d0[i] > d1[i] ? t0[i] : t1[i]
        }
        return result
      } else {
        console.warn('Survey is not finished yet.')
        return undefined
      }
    }
  },
  watch: {
    done: function(newState) {
      if (newState === true) {
        this.$emit('surveyDone', this.surveyResultMBTI)
      }
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: [
            'E(외향)',
            'S(감각)',
            'T(사고)',
            'J(판단)',
            'I(내향)',
            'N(직관)',
            'F(감정)',
            'P(인식)'
          ],
          labels: { show: true }
        },
        yaxis: {
          labels: { show: false },
          min: -1,
          max: 3,
          tickAmount: 4
        }
      },
      done: false,
      answerString: '',
      currentQuestionIndex: 0,
      questions: [
        {
          id: 1,
          axis: 'J-P',
          question: '평소 당신의 방은 어떤 모습인가요?',
          answers: [
            {
              text: "'미니멀 라이프'\n정갈하게 정돈되어 있다.",
              result: 'J'
            },
            {
              text:
                "'혼돈 속의 질서'\n지저분해 보이지만 생활패턴에 맞춰진 구조",
              result: 'P'
            }
          ]
        },
        {
          id: 2,
          axis: 'E-I',
          question: '집에 대한 당신의 가치관은?',
          answers: [
            {
              text: '집은 나만의 안락한 공간이다.',
              result: 'I'
            },
            {
              text: '집은 나의 개성을 표현하는 수단이다.',
              result: 'E'
            }
          ]
        },
        {
          id: 3,
          axis: 'J-P',
          question: ' 당신이 좋아하는 건축물의 형태는?',
          answers: [
            {
              text: "'한치의 흐트러짐도 없이'\n딱딱 맞는 대칭과 깔맞춤",
              result: 'J'
            },
            {
              text: "'요새는 이게 대세'\n비대칭과 비정형",
              result: 'P'
            }
          ]
        },
        {
          id: 4,
          axis: 'T-F',
          question: '건축가가 자신의 작품에 대해 설명할 때 당신은?',
          answers: [
            {
              text:
                '작품에 담긴 건축가의 질서정연한\n논리와 완벽성에 감탄한다.',
              result: 'T'
            },
            {
              text: '건축물의 멋진 형태와 공간감에 압도되어\n감동을 느낀다.',
              result: 'F'
            }
          ]
        },
        {
          id: 5,
          axis: 'S-N',
          question: ' 이사를 하고 나서 방을 꾸밀 때 당신은?',
          answers: [
            {
              text: '방의 레이아웃과 전체적 느낌을 중요시한다.',
              result: 'N'
            },
            {
              text: '‘바보야, 문제는 디테일이야.’\n디테일이 제일 중요하다.',
              result: 'S'
            }
          ]
        },
        {
          id: 6,
          axis: 'E-I',
          question: ' 평소 주말에 당신은?',
          answers: [
            {
              text: '나는 혼자놀기의 달인!\n집순이처럼 집콕한다.',
              result: 'I'
            },
            {
              text: '나는야 프로캠핑러.\n친구와 함께 캠핑을 떠난다.',
              result: 'E'
            }
          ]
        },
        {
          id: 7,
          axis: 'T-F',
          question: '룸메이트가 고민을 털어놓았을 때\n당신은?',
          answers: [
            {
              text: '친구한테 도움이 될만한 해결책을 제시해준다.',
              result: 'T'
            },
            {
              text: '함께 공감하며 맞장구 쳐준다.',
              result: 'F'
            }
          ]
        },
        {
          id: 8,
          axis: 'E-I',
          question: '모처럼만의 연휴에 당신은?',
          answers: [
            {
              text: '침대에 누워서 놓쳤던 드라마를 정주행한다.',
              result: 'I'
            },
            {
              text: '친구들과의 파자마 파티를 위해 방을 꾸민다.',
              result: 'E'
            }
          ]
        },
        {
          id: 9,
          axis: 'J-P',
          question: '여행을 계획할 때 당신은?',
          answers: [
            {
              text: '모든 동선이 머리에 그려질 때까지\n치밀한 계획을 세운다.',
              result: 'J'
            },
            {
              text:
                '숙소만 예약하면 됐지~\n세렌디피티를 추구하는 유연한 계획을 세운다.',
              result: 'P'
            }
          ]
        },
        {
          id: 10,
          axis: 'S-N',
          question: '어떤 장소에 처음 갈 때 당신은?',
          answers: [
            {
              text: '딱 보면 척이지. 이쯤이였던 거 같은데?',
              result: 'N'
            },
            {
              text: '지도 앱을 계속 켜놓고 자세히 살피며 길을 찾는다.',
              result: 'S'
            }
          ]
        },
        {
          id: 11,
          axis: 'T-F',
          question: '힙한 공간의 카페를 찾았다.\n친구한테 추천해줄 때 당신은?',
          answers: [
            {
              text:
                '공간의 어떤 요소로 인해서 추천 할 만한지\n분석적으로 설명한다.',
              result: 'T'
            },
            {
              text: '공간의 분위기와 느낌을 비유적으로 알려준다.',
              result: 'F'
            }
          ]
        },
        {
          id: 12,
          axis: 'S-N',
          question: '새로운 공간을 여행하고 돌아온 당신은?',
          answers: [
            {
              text:
                '여행지에서 느꼈던 분위기가 상징적이고 비유적으로 떠오른다.',
              result: 'N'
            },
            {
              text: '여행지의 인상 깊었던 구체적 포인트들이 기억난다.',
              result: 'S'
            }
          ]
        }
      ]
    }
  },
  props: {
    msg: String
  }
}
</script>


