<template>
  <!-- 1단계: MBTI설문 -->
  <Survey v-if="!isSurveyDone" @surveyDone="onSurveyDone" msg="injected message" />

  <div
    v-if="isProcessing"
    class="h-screen flex flex-col justify-center content-center justify-center pt-10 pb-24 pl-4 pr-4"
  >
    <div class="text-red-500 text-2xl">분석중</div>
  </div>

  <!-- 2단계: MBTI결과처방 & 집디자인 추천 -->
  <div
    v-if="isSurveyDone && !isHouseSelected && !isProcessing"
    class="h-screen flex flex-col flex-nowrap justify-between pt-10 pb-24 pl-4 pr-4"
  >
    <!-- 2-1 처방 -->
    <div class="text-2xl text-red-500 font-bold mb-4">당신은</div>
    <div class="text-red-500 text-left">
      <div v-for="item in prescriptions" :key="item[0]" class="mb-2 flex flex-row">
        <div class="text-xl leading-none w-16 flex-shrink-0 font-bold">{{item[0]}}</div>
        <div class="text-xl leading-none">{{item[1]}}</div>
      </div>
    </div>

    <!-- 2-2 집추천 -->
    <div class="text-2xl text-red-500 font-bold">당신의 집은 ({{ surveyResult }})</div>
    <div class="ml-12 mr-12">
      <img :src="'/model/house/thumbnail_4_3/'+surveyResult+'.png'"/>
    </div>
    <div class="text-red-500 text-xl">당신과 비슷한 성격의 다른집</div>
    <div class='flex flex-row flex-nowrap justify-around'>
      <div><img src="/model/house/thumbnail_4_3/INFJ.png" class="w-24 h-18"></div>
      <div><img src="/model/house/thumbnail_4_3/INFP.png" class="w-24 h-18"></div>
    </div>
    <button
      @click="onUserSelectHouse"
      class="px-4 py-1 rounded-full border border-red-500 text-red-500 bg-gray-300"
    >Next</button>
  </div>

  <div
    v-if="isSurveyDone && isHouseSelected && !isMaterialSelected"
    class="h-screen flex flex-col flex-nowrap justify-between pt-10 pb-16 pl-2 pr-2"
  >
    <div class="text-4xl text-red-500">재료 커스터마이제이션</div>
    <div class="text-2xl text-red-500">
      <div>
        사용자는 선택한 집의 각 부분의 재료를 결정한다.
      </div>
      <div>
        <a href="https://angle-3d-demo.myshopify.com/collections/frontpage/products/apple-watch-strap">참고사례</a>
      </div>
    </div>
    <button
      @click="onUserSelectMaterial"
      class="px-4 py-1 rounded-full border border-red-500 text-red-500 bg-gray-300"
    >Next</button>
  </div>

  <div
    v-if="isSurveyDone && isHouseSelected && isMaterialSelected && !isSiteSelected"
    class="h-screen flex flex-col flex-nowrap justify-between pt-10 pb-16 pl-2 pr-2"
  >
    <div class="text-4xl text-red-500">사이트 커스터마이제이션</div>
    <div class="text-2xl text-red-500">
      앱은 집을 놓을 3가지 타입의 사이트
      <br />-- 해변, 숲속, 마을 -- 를 제시한다.
      <br />유저는 그 중 한 가지를 선택한다.
    </div>
    <button
      @click="onUserSelectSite"
      class="px-4 py-1 rounded-full border border-red-500 text-red-500 bg-gray-300"
    >Next</button>
  </div>

  <!-- TODO -->
  <!-- <HouseSelection></HouseSelection> -->
  <!-- <SiteSelection></SiteSelection> -->
</template>

<script>
import Survey from '@/components/Survey.vue'
// import HouseSelection from '@/components/HouseSelection.vue'
// import SiteSelection from '@/components/SiteSelection.vue'

export default {
  name: 'Create',
  prescriptionSource: {
    E: [
      'E(외향)',
      '외향적인 당신은 파노라믹 창을 통해 세상을 바라보고, 야외 데크에서 휴식을 즐깁니다.'
    ],
    S: [
      'S(감각)',
      '자연과 어울리는 감성적인 디자인은 감각적인 당신에게 어울립니다.'
    ],
    T: [
      'T(사고)',
      '군더더기 없는 기능적 구조와 깔끔하고 반듯한 디자인은 기능과 논리를 중시하는 당신의 취향을 저격합니다'
    ],
    J: [
      'J(판단)',
      '좌우 대칭으로 정갈하게 정돈된 집은 당신의 집중력을 향상시킵니다.'
    ],
    I: [
      'I(내향)',
      '내향적인 당신은 안락하게 보호된 공간에서 나만의 사색을 할 수 있습니다.'
    ],
    N: [
      'N(직관)',
      '기존에는 볼 수 없었던 미래적인 디자인은 미래지향적인 당신에게 어울립니다.'
    ],
    F: ['F(감정)', '건축물의 장식적 요소는 당신의 감정을 움직입니다.'],
    P: [
      'P(인식)',
      '자연스럽고 고급스러운 비대칭은 자유로운 당신의 개성을 표현합니다.'
    ]
  },
  components: {
    Survey
    // HouseSelection,
    // SiteSelection
  },
  data() {
    return {
      isSurveyDone: false,
      isProcessing: false,
      isHouseSelected: false,
      isMaterialSelected: false,
      isSiteSelected: false,
      surveyResult: '',
      houseSelectionResult: '',
      siteSelectionResult: '',
      prescriptions: []
    }
  },
  methods: {
    onSurveyDone(result) {
      this.isSurveyDone = true
      this.surveyResult = result

      this.isProcessing = true
      setTimeout(() => {
        this.isProcessing = false
      }, 3000)

      console.log(this.surveyResult)
      console.log(this.surveyResult[0])
      for (var i = 0; i < this.surveyResult.length; i++) {
        this.prescriptions.push(
          this.$options.prescriptionSource[this.surveyResult[i]]
        )
      }
    },
    onUserSelectHouse(result) {
      this.isHouseSelected = true
      this.houseSelectionResult = result
    },
    onUserSelectMaterial(result) {
      this.isMaterialSelected = true;
    },
    onUserSelectSite(result) {
      this.isSiteSelected = true
      this.siteSelectionResult = result
      this.$router.push({
        name: 'house',
        params: { houseId: this.surveyResult }
      })
    }
  },
  watch: {}
}
</script>
